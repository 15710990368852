import React, { Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Category from "../../components/ProductCategory";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CategoryLoading from "../../components/PlaceHolder/CategoryLoading";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCategoryProducts } from "../../network/product";
import { useTranslation } from "react-i18next";

const ProductCategoryPage = ({ user }) => {
  const { category } = useParams();
  const { i18n } = useTranslation();

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listCategoryProducts, category, i18n],
    queryFn: () => listCategoryProducts(category),
    enabled: !!category && !!i18n,
  });

  if (isLoading) {
    return (
      <Fragment>
        <Navbar user={user} />
        <CategoryLoading isLoading={isLoading} />
        <Footer />
      </Fragment>
    );
  }

  return (
    <Fragment className="pt-5 pb-5 bg-[#eff0f5]">
      <Navbar user={user} />
      <Category
        Category={category}
        ProductData={productData?.data}
        user={user} />
      <Footer />
    </Fragment>
  );
};

export default ProductCategoryPage;
