import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import SuccessPayment from "../../components/SuccessPayment";

class PaymentSuccess extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User} />
        <SuccessPayment />
        <Footer />
      </Fragment>
    );
  }
}

export default PaymentSuccess;
