import React, { Component, Fragment } from "react";
import AllCategory from "../../components/Category";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

class Category extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar  user={User}/>
        <AllCategory  user={User}/>
        <Footer />
      </Fragment>
    );
  }
}

export default Category;
