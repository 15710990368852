import React, { useState } from "react";
import { Row, Col } from "antd";
import cogoToast from "cogo-toast";
import { Link, Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CategoryLoading from "../PlaceHolder/CategoryLoading";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { listFavourites, removeFavourite } from "../../network/favourite";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { Card, Empty, Flex, Typography, Button, Rate } from 'antd';
import { ShoppingCartOutlined, HeartFilled } from "@ant-design/icons";
const { Meta } = Card;

const Favorite = ({ user }) => {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [currentProductId, setCurrentProductId] = useState(null);

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listFavorites, i18n],
    queryFn: listFavourites,
    enabled: !!i18n,
  });

  const removeItem = useMutation({
    mutationFn: (id) => removeFavourite(id),
    onSuccess: (data) => {
      cogoToast.success(t('messages.removedFromFavorites'), { position: "top-right" });
      queryClient.invalidateQueries([queryKeys.listFavorites]);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    },
  });

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return (
      <div className="p-10 bg-gray-100">
        <div className="container">
          <CategoryLoading isLoading="true" />
        </div>
      </div>
    );
  }

  if (!productData.data || productData.data.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <Empty description={t('noFavouriteProducts')} />
      </div>
    );
  }

  const MyView = productData?.data.map((item, i) => (
    <Col xl={6} lg={4} md={8} sm={12} xs={24} key={i.toString()}>
      <div className="relative mb-5">
        <Card
          hoverable
          className="mb-5 xl:w-[270px] 2xl:w-full"
          cover={
            <div className="relative">
              <Link to={`/products/${item.product.id}/details`} className="custom-links" style={{ textDecoration: "none" }}>
                <img
                  alt="example"
                  src={item?.product?.images[0]}
                  className="w-full"
                />
              </Link>

              <Button onClick={() => removeItem.mutate(item.id)}
                className="absolute top-2 right-2 bg-transparent border-none shadow-none"
                shape="circle" icon={<HeartFilled className="text-red-500" style={{ fontSize: '26px' }} />}
              />

            </div>
          }
        >
          <Meta title={item.product.name} description={
            <div>
              <Rate disabled defaultValue={item.product.average_rating} />

              <Flex justify="space-between" className="mt-3">
                <div>
                  {item.product.new_price === null ? (
                    <Typography.Text className="text-[#0F63E3]">{item.product.price}{t('currency')}</Typography.Text>
                  ) : (
                    <>
                      <Typography.Text className="text-[#0F63E3]">{item.product.new_price}{t('currency')}</Typography.Text>
                      <Typography.Text delete type="secondary" className="mx-2">{item.product.price}{t('currency')}</Typography.Text>
                    </>
                  )}
                </div>
                <Button type="primary" onClick={() => addToCart(item.id)}
                  loading={isCartPending && currentProductId === item.id}
                  icon={<ShoppingCartOutlined />} size={"lg"} />
              </Flex>
            </div>
          } />
        </Card>
      </div>
    </Col>
  ));

  return (
    <div className="pt-5 pb-5 bg-gray-100">
      <div className="container">
        <Typography.Title
          level={4}
        >
          {t('favouriteHeader')}
        </Typography.Title>
        <Typography.Title
          level={5}
          type="secondary"
        >
          {t('removeProductsFavorite')}
        </Typography.Title>
        <Row gutter={10} className="mt-5">{MyView}</Row>
      </div>
    </div>
  );
};

export default Favorite;
