import React, { Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import OfferDetails from "../../components/OfferDetails";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { offerDetails } from "../../network/offer";
import { useTranslation } from "react-i18next";
import CartLoading from "../../components/PlaceHolder/CartLoading";

const OfferDetailsPage = ({ user }) => {
  const { id: code } = useParams();
  const { i18n } = useTranslation();

  const { data: offerData, isLoading } = useQuery({
    queryKey: [queryKeys.listOffers, code, i18n],
    queryFn: () => offerDetails(code),
    enabled: !!i18n && !!code,
  });

  if (isLoading) {
    return (
      <Fragment>
        <Navbar user={user} />
        <CartLoading isLoading={isLoading} />
        <Footer />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Navbar user={user} />
      <OfferDetails user={user} data={offerData?.data} />
      <Footer />
    </Fragment>
  );
};

export default OfferDetailsPage;