import React, { useState } from "react";
import { Button, Rate, Typography } from "antd";
import { queryKeys } from "../../../services/react-query/queryKeys";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { listPopularProducts } from "../../../network/product";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { addCart } from "../../../network/cart";
import cogoToast from "cogo-toast";
import { ShoppingOutlined } from "@ant-design/icons";
import PopularProductLoading from "../../PlaceHolder/PopularProductLoading";

const TopProducts = ({ user }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [currentProductId, setCurrentProductId] = useState(null);

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listPopularProducts, i18n],
    queryFn: listPopularProducts,
    enabled: !!i18n,
  });

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        navigate('/cart');
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        QueryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.errorOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (isLoading) {
    return <PopularProductLoading />;
  }

  return (
    <div>
      <div className="container">
        {/* Header section */}
        <div className="text-center mb-24">
          <p data-aos="fade-up" className="text-sm text-primary">
            {t('home.topRated')}
          </p>
          <Typography.Title
            level={2}
          >
            {t('home.popularProducts')}
          </Typography.Title>
          <Typography.Text data-aos="fade-up" className="text-xs text-gray-400">
            {t('home.popularProductDescription')}
            <Link className="text-decoration-none" to="/products/popular">
              &nbsp;     {t('home.see')} {t('home.more')}
            </Link>
          </Typography.Text>
        </div>
        {/* Body section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-20 md:gap-5 place-items-center">
          {productData?.data && productData?.data.map((item) => (
            <div
              data-aos="zoom-in"
              className="mb-20 rounded-2xl bg-white hover:bg-[rgba(0,123,255,0.4)] hover:text-black relative shadow-xl duration-300 group max-w-[300px]"
            >
              {/* image section */}
              <Link className="custom-links" to={`/products/${item.id}/details`} style={{ textDecoration: "none" }}>
                <div className="h-[100px]">
                  <img
                    src={item.images[0]}
                    alt=""
                    className="max-w-[140px] block mx-auto transform -translate-y-20 group-hover:scale-105 duration-300 drop-shadow-md"
                  />
                </div>
              </Link>

              {/* details section */}
              <div className="p-4 text-center">
                <div className="w-full flex items-center justify-center gap-1">
                  <Rate disabled defaultValue={item.average_rating} />
                </div>
                <h1 className="text-xl font-bold">{item.name}</h1>
                <p className="text-gray-500 group-hover:text-black duration-300 text-sm line-clamp-2">
                  {item.description}
                </p>
                <Button
                  type="primary"
                  className="bg-primary hover:scale-105 duration-300 text-white py-1 px-4 rounded-full mt-4 group-hover:bg-white group-hover:text-black"
                  icon={<ShoppingOutlined />}
                  onClick={() => addToCart(item.id)}
                  loading={isCartPending && currentProductId === item.id} 
                  size="large"
                >
                  {t('home.buy')}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
