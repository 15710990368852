import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Favorite from "../../components/Favorite";

class FavoritePage extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User} />
        <Favorite user={User} />
        <Footer />
      </Fragment>
    );
  }
}

export default FavoritePage;
