import React, { Component, Fragment } from "react";

class HeroLoading extends Component {
  render() {
    let isLoading = this.props.isLoading;
    
    return (
      <Fragment>
        <div className={isLoading} >
          <div className="container mt-4">
            <div className={`${this.props.loaderDiv} mx-3`}>
              <div className="ph-item">
                <div className="ph-col-12">
                  <div className="ph-picture"></div>
                  <div className="ph-row">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HeroLoading;
