import React from "react";
import { Card, Form, Button, Input, Typography, Flex } from "antd";
import { Link } from "react-router-dom";
import { authForgotPassword } from "../../network/auth";
import cogoToast from "cogo-toast";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { mutate: onForgotPassword, isPending } = useMutation({
    mutationFn: (formData) => authForgotPassword(formData),
    onSuccess: (data) => {
      cogoToast.success(data.message);
      form.resetFields();
    },
    onError: () => {
      cogoToast.warn(t("auth.errorMessage"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    onForgotPassword(values);
  };

  return (
    <div className="bg-[#eff0f5]">
      <div className="container p-10">
        <Typography.Title level={4} className="text-center mt-10">
          {t("auth.forgotPasswordTitle")}
        </Typography.Title>

        <Typography.Title level={5} type="secondary" className="text-center">
          {t("auth.alreadyMember")}{" "}
          <Link to="/register">{t("auth.login")}</Link>{" "}
          {t("auth.here")}
        </Typography.Title>
        <Flex justify="center" className="my-10">
          <Card style={{ width: "600px" }}>
            <Form
              form={form}
              name="forgotPassword"
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                required={false}
                name="email"
                label={t("auth.email")}
                rules={[
                  { type: 'email', message: t("auth.emailValidation") },
                  { required: true, message: t("auth.emailValidationRequired") },
                ]}
              >
                <Input placeholder={t("auth.emailPlaceholder")} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  loading={isPending}
                  htmlType="submit"
                  className="xl:w-[200px]"
                >
                  {t("auth.submit")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Flex>
      </div>
    </div>
  );
};

export default ForgotPassword;
