import React, { useState } from "react";
import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { withTranslation } from 'react-i18next';
import { Card, Button, Flex, Typography, Empty, Col, Row, Rate } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { addCart } from "../../network/cart";
import { useMutation } from "@tanstack/react-query";
import queryClient from "../../services/react-query/queryClient";
import { queryKeys } from "../../services/react-query/queryKeys";

const { Meta } = Card;

const Category = ({ t, user, ProductData }) => {
  const [currentProductId, setCurrentProductId] = useState(null);

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (ProductData?.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <div className="container">
          <Empty description={t('emptyMessage')} />
        </div>
      </div>
    );
  }

  return (
    <div className="p-10 bg-gray-100">
      <div className="container">
        <Typography.Title
          level={4}
        >
          {t('cp')}
        </Typography.Title>
        <Row gutter={10} className="mt-5">
          {ProductData?.map((item, i) => (
            <Col xl={6} lg={6} md={8} sm={12} xs={24} key={i.toString()}>
              <Card
                hoverable
                className="mb-5 xl:w-[270px] 2xl:w-full"
                cover={
                  <Link to={`/products/${item.id}/details`} className="custom-links" style={{ textDecoration: "none" }}>
                    <img
                      alt="example"
                      src={item.images[0]}
                      className="w-full"
                    />
                  </Link>
                }
              >
                <Meta title={item?.name} description={
                  <div>
                    <Rate disabled defaultValue={item.average_rating} />
                    <Flex justify="space-between" className="mt-3">
                      <div>
                        {item.new_price === null ? (
                          <Typography.Text className="text-[#0F63E3]">{item.price}{t('currency')}</Typography.Text>
                        ) : (
                          <>
                            <Typography.Text className="text-[#0F63E3]">{item.new_price}{t('currency')}</Typography.Text>
                            <Typography.Text delete type="secondary" className="mx-2">{item.price}{t('currency')}</Typography.Text>
                          </>
                        )}
                      </div>

                      <Button type="primary" onClick={() => addToCart(item.product.id)}
                        loading={isCartPending && currentProductId === item.id}
                        icon={<ShoppingCartOutlined />} size={"lg"} />
                    </Flex>
                  </div>
                } />

              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default withTranslation()(Category);
