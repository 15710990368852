import { useMutation } from '@tanstack/react-query';
import cogoToast from 'cogo-toast';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { addTransaction } from '../../network/transaction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Result, Spin } from 'antd';

const SuccessPayment = ({ t }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isTransactionSuccessful, setIsTransactionSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isProcessed = useRef(false);

  const getQueryParams = (search) => new URLSearchParams(search);

  const addTransactionMutation = useMutation({
    mutationFn: (params) => addTransaction(params),
    onSuccess: (data) => {
      if (data.status === "true") {
        setIsTransactionSuccessful(true);
        setIsLoading(false);
        cogoToast.success(t('transactionSuccessful'), {
          position: 'top-right',
        });

        setTimeout(() => {
          navigate('/orders');
        }, 1000);
      } else {
        setIsTransactionSuccessful(false);
        setIsLoading(false);
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
    },
    onError: () => {
      setIsLoading(false);
      setIsTransactionSuccessful(false);
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    },
  });

  useEffect(() => {
    if (!isProcessed.current) {
      const queryParams = getQueryParams(location.search);
      const tranRef = queryParams.get('tranRef');
      const orderId = queryParams.get('orderId');

      if (tranRef && orderId) {
        addTransactionMutation.mutate({
          order_id: orderId,
          reference: tranRef,
        });
        isProcessed.current = true;
      }
    }
  }, [location.search, addTransactionMutation]);

  return (
    <div className="p-10 bg-gray-100">
      <div className="container">
        {isLoading ? (
          <Flex justify='center'><Spin size="large" /></Flex>

        ) : isTransactionSuccessful ? (
          <Result
            status="success"
            title={t('transactionSuccessful')}
          />
        ) : (
          <Result
            status="warning"
            title={t('messages.warningOccurred')}
          />
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SuccessPayment);
