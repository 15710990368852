import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import cogoToast from "cogo-toast";
import Slider from "react-slick";
import ProductLoading from "../../PlaceHolder/ProductLoading";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { listProducts } from "../../../network/product";
import { queryKeys } from "../../../services/react-query/queryKeys";
import { addCart } from "../../../network/cart";
import { Card, Button, Flex, Typography, Rate } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';

const { Meta } = Card;

const Product = ({ t, user, i18n }) => {
  const queryClient = useQueryClient();
  const [currentProductId, setCurrentProductId] = useState(null);
  const isRTL = i18n.language === 'ar';

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listProducts, i18n],
    queryFn: listProducts,
    enabled: !!i18n,
  });

  const { mutate: onAddCart, isPending: isCartPending } = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t("messages.itemAddedSuccessfully"), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      }
      setCurrentProductId(null);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
      setCurrentProductId(null);
    }
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t("messages.addItemsLoginRequired"), {
        position: "top-right",
      });
    } else {
      setCurrentProductId(productId);
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart(formData);
    }
  };

  if (isLoading) {
    return <ProductLoading />;
  }

  var settings = {
    dots: "true",
    arrows: false,
    infinite: "true",
    speed: 500,
    slidesToScroll: 1,
    autoplay: "true",
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: "true",
    pauseOnFocus: "true",
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: productData?.data && productData?.data.length < 5 ? productData?.data.length : 4,
          slidesToScroll: 1,
          infinite: "true",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const MyView = productData?.data && productData?.data.map((item, i) => {
    return (
      <div key={i} className={`my-6 flex justify-center ${isRTL ? 'rtl-card' : ''}`}>
        <Card
          hoverable
          className="mb-5 xl:w-[260px]"
          cover={
            <Link to={`/products/${item.id}/details`} className="custom-links" style={{ textDecoration: "none" }}>
              <img
                alt="example"
                src={item.images[0]}
                className="w-full"
              />
            </Link>
          }
        >
          <Meta
            title={item.name}
            description={
              <div>
                <Rate disabled defaultValue={item.average_rating} />

                <Flex justify="space-between" className="mt-3">
                  <div>
                    {item.new_price === null ? (
                      <Typography.Text className="text-[#0F63E3]">{item.price}{t('currency')}</Typography.Text>
                    ) : (
                      <>
                        <Typography.Text className="text-[#0F63E3]">{item.new_price}{t('currency')}</Typography.Text>
                        <Typography.Text delete type="secondary" className="mx-2">{item.price}{t('currency')}</Typography.Text>
                      </>
                    )}
                  </div>
                  <Button
                    type="primary"
                    onClick={() => addToCart(item.id)}
                    loading={isCartPending && currentProductId === item.id}
                    icon={<ShoppingCartOutlined />}
                    size={"lg"}
                  />
                </Flex>
              </div>
            }
          />
        </Card>
      </div>
    );
  });

  return (
    <div className={`container my-20 ${isRTL ? 'rtl-container' : ''}`}>
      <div className="text-center">
        <Typography.Title level={2}>
          {t('home.products')}
        </Typography.Title>
        <Typography.Text data-aos="fade-up" className="text-xs text-gray-400">
          {t('home.productDescription')}
          <Link className="text-decoration-none" to="/products">
            &nbsp;{t('home.see')} {t('home.more')}
          </Link>
        </Typography.Text>
      </div>
      <div data-aos="zoom-in">
        <Slider {...settings}>
          {MyView}
        </Slider>
      </div>
    </div>
  );
};

export default withTranslation()(Product);
