import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/Home';
import UserLoginPage from '../pages/Auth/UserLoginPage';
import UserRegisterPage from '../pages/Auth/UserRegisterPage';
import ForgotPasswordPage from '../pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from '../pages/Auth/ResetPasswordPage';
import ContactPage from '../pages/Contact';
import ProductDetailsPage from '../pages/ProductDetails';
import OfferDetailsPage from '../pages/OfferDetails';
import NotFoundPage from '../pages/NotFound';
import FavoritePage from '../pages/Favorite';
import CartPage from '../pages/Cart';
import OrderTrackingPage from '../pages/TrakingOrder';
import AllCategoryPage from '../pages/Category';
import AllProductPage from '../pages/Product';
import AllPopularProductPage from '../pages/PopularProduct';
import AllOfferPage from '../pages/Offer';
import ProductCategoryPage from '../pages/ProductCategory';
import SearchPage from '../pages/Search';
import ProfilePage from '../pages/UserProfile';
import OrderListPage from '../pages/Order';
import { auth } from '../network/auth';
import { queryKeys } from '../services/react-query/queryKeys';
import { useQuery } from '@tanstack/react-query';
import PrivacyPage from '../pages/Privacy';
import PaymentSuccess from '../pages/PaymentSuccess';

const AppRoute = () => {
  const [user, setUser] = useState({});

  const { data } = useQuery({
    queryKey: [queryKeys.auth],
    queryFn: auth,
  });

  useEffect(() => {
    if (data) {
      setUser(data.data);
    }
  }, [data]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<HomePage user={user} setUser={setUser} />} />
        <Route path="/login" element={<UserLoginPage user={user} setUser={setUser} />} />
        <Route path="/register" element={<UserRegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/profile" element={<ProfilePage user={user} setUser={setUser} />} />
        <Route path="/contact" element={<ContactPage user={user} setUser={setUser} />} />
        <Route path="/privacy-policy" element={<PrivacyPage user={user} />} />
        <Route path="/products/:code/details" element={<ProductDetailsPage user={user} />} />
        <Route path="/products/categories/:category" element={<ProductCategoryPage user={user} setUser={setUser} />} />
        <Route path="/products/popular" element={<AllPopularProductPage user={user} />} />
        <Route path="/products" element={<AllProductPage user={user} setUser={setUser} />} />
        <Route path="/offers/:id/details" element={<OfferDetailsPage user={user} />} />
        <Route path="/offers" element={<AllOfferPage user={user} />} />
        <Route path="/search/:searchKey" element={<SearchPage user={user} setUser={setUser} />} />
        <Route path="/favorites" element={<FavoritePage user={user} />} />
        <Route path="/cart" element={<CartPage user={user} setUser={setUser} />} />
        <Route path="/categories" element={<AllCategoryPage user={user} setUser={setUser} />} />
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/orders" element={<OrderListPage user={user} setUser={setUser} />} />
        <Route path="/orders/tracking/:code" element={<OrderTrackingPage user={user} setUser={setUser} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Fragment>
  );
}

export default AppRoute;
