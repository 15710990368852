import React, { Component } from "react";
import Order from "../../components/Order";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

class OrderListPage extends Component {
  render() {
    const User = this.props.user;
    return (
      <div>
        <Navbar user={User} />
        <Order user={User} />
        <Footer />
      </div>
    );
  }
}

export default OrderListPage;
