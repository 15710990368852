import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: process.env.BACKEND_URL,
  // timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "content-language": "en",
    withCredentials: true,
  },
});


axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + localStorage.getItem("token");
  return config;
});