import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Products from '../../components/Home/Product';
import Hero from '../../components/Home/Hero';
import TopProducts from '../../components/Home/TopProducts/TopProducts';
import Banner from '../../components/Home/Banner/Banner';
import { getVisitor } from '../../network/visitor';

const HomePage = ({ user }) => {
  useEffect(() => {
    const fetchVisitorDetails = async () => {
      try {
        await getVisitor();
      } catch (error) {
        console.error("Error fetching visitor details:", error);
      }
    };

    fetchVisitorDetails();
  }, []);

  return (
    <Fragment>
      <Navbar user={user} />
      <Hero user={user} />
      <Products user={user} />
      <TopProducts user={user} />
      <Banner  />
      <Footer />
    </Fragment>
  );
};

export default HomePage;
