import React from "react";
import footerLogo from "../../assets/images/logo.png";
import {
  FacebookFilled,
  HomeOutlined,
  InstagramFilled,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

const Footer = ({ i18n, t }) => {
  const FooterLinks = [
    {
      title: t('footer.home'),
      link: "/",
    },
    {
      title: t('footer.aboutUs'),
      link: "/",
    },
    {
      title: t('footer.contactUs'),
      link: "/contact",
    },
    {
      title: t('footer.privacyPolicy'),
      link: "/privacy-policy",
    }
  ];

  const FooterImporantentLinks = [
    {
      title: t('footer.wishlist'),
      link: "/favorites",
    },
    {
      title: t('footer.myCart'),
      link: "/cart",
    },
    {
      title: t('footer.trackOrder'),
      link: "/orders",
    },
    {
      title: t('footer.orders'),
      link: "/orders",
    }
  ];
  const isRTL = i18n.language === 'ar';

  return (
    <div className="text-black shadow-inner">
      <div className="mx-10">
        <div data-aos="zoom-in" className="grid md:grid-cols-3 pb-5">
          <div className="py-8 px-4">
            <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
              <img src={footerLogo} alt="" className="w-[240px] h-[50px]" />
            </h1>
            <p>
              {t('footer.footerDescription')}
            </p>
          </div>

          {/* Footer Links */}
          <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10">
            <div>
              <div className="py-8 px-4">
                <h1 className={`sm:text-xl text-xl font-bold mb-3 ${isRTL ? '' : 'sm:text-left text-justify'}`}>
                  {t('footer.importantLinks')}
                </h1>
                <ul className="flex flex-col gap-3">
                  {FooterImporantentLinks.map((link) => (
                    <li
                      className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200"
                      key={link.title}
                    >
                      <Link to={link.link} className="text-gray-500" style={{ textDecoration: "none", }}>
                        <span>{link.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <div className="py-8 px-4">
                <h1 className={`sm:text-xl text-xl font-bold mb-3 ${isRTL ? '' : 'sm:text-left text-justify'}`}>
                  {t('footer.links')}
                </h1>
                <ul className="flex flex-col gap-3">
                  {FooterLinks.map((link) => (
                    <li
                      className="cursor-pointer hover:text-primary hover:translate-x-1 duration-300 text-gray-200"
                      key={link.title}
                    >
                      <Link to={link.link} className="text-gray-500" style={{ textDecoration: "none" }}>
                        <span>{link.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* social links */}

            <div className="py-8 px-4">
              <div className="flex items-center gap-3">
                <a href="https://www.instagram.com/shopi.sonic" target="_blank" rel="noopener noreferrer">
                  <InstagramFilled className="text-3xl" />
                </a>
                <a href="https://www.facebook.com/shopisonic" target="_blank" rel="noopener noreferrer">
                  <FacebookFilled className="text-3xl" />
                </a>
              </div>
              <div className="mt-6">
                <div className="flex items-center gap-3">
                  <HomeOutlined />
                  <p>Saudi Arabia</p>
                </div>
                <div className="flex items-center gap-3 mt-3">
                  <PhoneOutlined />
                  <p>+201004753538</p>
                </div>
                <div className="flex items-center gap-3 mt-3">
                  <MailOutlined />
                  <p>support@shopisonic.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="text-center text-black pb-4" >
          <Link target="_blank" to="https://techiesonic.com">
            {t('footer.copyright')}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);

