import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { countCart } from "../../network/cart";
import { useQuery } from "@tanstack/react-query";
import { Badge, Button, Layout, Menu, Dropdown, Space } from 'antd';
import { HeartOutlined, ShoppingCartOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import langIconEn from "../../assets/images/en.png"
import langIconAr from "../../assets/images/ar.png"

const { Header } = Layout;

const NavMenuDesktop = ({ t, i18n, user }) => {

  const { data: cartCount } = useQuery({
    queryKey: ['cartCount', user?.id],
    queryFn: countCart,
    enabled: !!user,
  });

  const handleLanguageChange = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ar');
    }
  };


  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const items = [
    {
      key: '1',
      label: (
        <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/profile">
          {t('profile')}
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/orders">
          {t('orders')}
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/" onClick={logout}>
          {t('logout')}
        </Link>
      ),
    }
  ];

  return (
    <Layout className="p-3">
      <Header style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
        <Button
          className="border-none xl:mx-5"
          onClick={handleLanguageChange}
          icon={
            i18n.language === 'ar' ? (
              <img
                src={langIconAr}
                alt=""
                className="border-none mt-3 xl:w-[60px] xl:h-[50px] md:w-[60px] md:h-[50px] lg:w-[60px] lg:h-[50px] w-[35px] h-[35px]"
              />
            ) : (
              <img
                src={langIconEn}
                alt=""
                className="border-none mt-3 xl:w-[60px] xl:h-[50px] md:w-[60px] md:h-[50px] lg:w-[60px] lg:h-[50px] w-[35px] h-[35px]"
              />
            )
          }
        />

        <Menu theme="light" mode="horizontal" style={{ flex: 1, justifyContent: 'center' }} breakpoint="lg" collapsedWidth="0">
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Menu.Item>
              <Link to="/">
                <img
                  src={require('../../assets/images/logo.png')}
                  alt="logo"
                  className="logo"
                />
              </Link>
            </Menu.Item>
          </div>
          {localStorage.getItem('token') ? (
            <div className="mt-2">
              <Menu.Item>
                <Link to="/favorites" className="text-black" style={{ textDecoration: 'none' }}>
                  <HeartOutlined className="responsive-icon" style={{ fontSize: '22px' }} />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/cart" className="text-black" style={{ textDecoration: 'none' }}>
                  <Badge count={cartCount} size="small">
                    <ShoppingCartOutlined className="responsive-icon" style={{ fontSize: '22px' }} />
                  </Badge>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Dropdown menu={{ items }}>
                  <button onClick={(e) => e.preventDefault()} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                    <Space>
                      <UserOutlined className="responsive-icon" style={{ fontSize: '21px' }} />
                      <DownOutlined className="responsive-icon" />
                    </Space>
                  </button>
                </Dropdown>
              </Menu.Item>
            </div>
          ) : (
            <div className="mt-1">
              <Menu.Item>
                <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/login" style={{ textDecoration: 'none' }}>
                  {t('auth.login')}
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold" to="/register" style={{ textDecoration: 'none' }}>
                  {t('auth.signUp')}
                </Link>
              </Menu.Item>
            </div>
          )}
        </Menu>
      </Header>
    </Layout>
  );
}

export default withTranslation()(NavMenuDesktop);