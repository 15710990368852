import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './App.css';
import AppRoute from './routes/AppRoute';
import QueryProvider from './services/react-query';
import { FloatButton } from 'antd';
import { MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const App = () => {
  const [className, setClassName] = useState('ltr');
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'ar') {
      setClassName('rtl');
    } else {
      setClassName('ltr');
    }
  }, [i18n.language]);

  return (
    <QueryProvider>
      <BrowserRouter>
        <div className={className}>
          <AppRoute />

          <FloatButton.Group
            trigger="hover"
            type="primary"
            icon={<QuestionCircleOutlined />}
          >

            <Link to="/contact">
              <FloatButton
                shape="square"
                icon={<MailOutlined />}
              />
            </Link>

            <Link to="/orders" >
              <FloatButton className='mt-2' icon={<img
                src={require('./assets/images/orders-icon.png')}
                alt="delivery-services-1"
                width="18px"
                height="26px"
              />} />
            </Link>

          </FloatButton.Group>
        </div>
      </BrowserRouter>
    </QueryProvider>
  );
};

export default App;
