import React, { Component, Fragment } from "react";
import { Col, Row } from "antd";

class CategoryLoading extends Component {
  render() {
    let isLoading = this.props.isLoading;

    return (
      <Fragment>
        <div className={isLoading}>
          <div className="container">
            <Row>
              <Col xl={8} lg={8} md={8} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={8} lg={8} md={8} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CategoryLoading;
