import React, { Fragment } from "react";
import { Row, Col, Button, Form, Input, Typography } from "antd";
import { useTranslation } from 'react-i18next';
import { contactUs } from "../../network/contact";
import 'react-toastify/dist/ReactToastify.css';
import { useMutation } from "@tanstack/react-query";
import cogoToast from "cogo-toast";

const Contact = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { mutate: onContact, isPending } = useMutation({
    mutationFn: (formData) => contactUs(formData),
    onSuccess: (data) => {
      form.resetFields();
      cogoToast.success(t("messages.messageSentSuccessfully"), { position: "top-right" });
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    }
  });

  const onFinish = (values) => {
    onContact(values);
  };

  return (
    <Fragment>
      <div className="py-5 bg-gray-100">
        <div className="container">
          <Typography.Title level={4}>{t('footer.contactUs')}</Typography.Title>
          <Row gutter={20}>
            <Col className="mb-4" xl={16} lg={16} md={24} sm={24} xs={24}>
              <Form
                form={form}
                name="contact"
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="name"
                  label={t('auth.name')}
                  required={false}
                  rules={[
                    { required: true, message: t('auth.nameValidationRequired'), whitespace: true },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t('auth.email')}
                  required={false}
                  rules={[
                    { type: 'email', message: t("auth.emailValidation") },
                    { required: true, message: t("auth.emailValidationRequired") },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="message"
                  label={t('message')}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('messageValidation'),
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" loading={isPending} htmlType="submit" className="w-full">
                    {t('send')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col className="mb-4" xl={8} lg={8} md={24} sm={24} xs={24}>
              <iframe
                title="ShopiSonic Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27626.21996235151!2d31.2273142!3d30.0444196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145846d9b085af4f%3A0xa69e98cfbcdca5f!2sCairo%2C%20Egypt!5e0!3m2!1sen!2sus!4v1699836921091!5m2!1sen!2sus"
                width="500"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                style={{ border: 0 }}
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
