import React from "react";
import { Card, Form, Button, Input, Flex, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from "../../services/react-query/queryKeys";
import queryClient from "../../services/react-query/queryClient";
import { authLogin } from "../../network/auth";
import { useMutation } from "@tanstack/react-query";

const UserLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: onLogin, isPending } = useMutation({
    mutationFn: (formData) => authLogin(formData),
    onSuccess: (data) => {
      if (data?.false !== false && data?.token) {
        localStorage.setItem("token", data.token);
        queryClient.invalidateQueries([queryKeys.userData]);
        cogoToast.success(t("auth.loginSuccess"), { position: "top-right" });
        navigate("/");
        window.location.reload();
      } else {
        cogoToast.warn(t("auth.invalidCredentials"), { position: "top-right" });
      }

    },
    onError: () => {
      cogoToast.warn(t("auth.errorOccurred"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    onLogin(values);
  };

  return (
    <div className="bg-[#eff0f5]">
      <div className="container p-10">
        <Typography.Title level={4} className="text-center mt-10">
          {t("auth.loginTitle")}
        </Typography.Title>
        <Typography.Title level={5} type="secondary" className="text-center">
          {t("auth.loginSubTitle")}{" "}
          <Link to="/register">{t("auth.signUpHere")}</Link>{" "}
          {t("auth.here")}
        </Typography.Title>

        <Flex justify="center" className="my-10">
          <Card style={{ width: "600px" }}>
            <Form form={form} name="login" onFinish={onFinish} layout="vertical">
              <Form.Item
                name="email"
                required={false}
                label={t("auth.email")}
                rules={[
                  { type: 'email', message: t("auth.emailValidation") },
                  { required: true, message: t("auth.emailValidationRequired") },
                ]}
              >
                <Input placeholder={t("auth.emailPlaceholder")} />
              </Form.Item>
              <Form.Item
                required={false}
                name="password"
                label={t("auth.password")}
                rules={[
                  { required: true, message: t("auth.passwordValidationRequired") },
                ]}
                hasFeedback
              >
                <Input.Password placeholder={t("auth.passwordPlaceholder")} />
              </Form.Item>
              <Flex justify="space-between" align="center">
                <Form.Item>
                  <Button
                    type="primary"
                    loading={isPending} 
                    htmlType="submit"
                    className="xl:w-[200px]"
                  >
                    {t("auth.login")}
                  </Button>
                </Form.Item>
                <Link to="/forgot-password">{t("auth.forgotPassword")}</Link>
              </Flex>
            </Form>
          </Card>
        </Flex>
      </div>
    </div>
  );
};

export default UserLogin;
