import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Privacy from "../../components/Privacy";

class PrivacyPage extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User} />
        <Privacy />
        <Footer />
      </Fragment>
    );
  }
}

export default PrivacyPage;
