import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Typography } from 'antd';

const { Title, Paragraph } = Typography;

const Privacy = ({ t }) => {
  return (
    <div className="p-10 bg-gray-100">
      <div className="container">
        <Col xl={18} lg={18} md={16} sm={24} xs={24} className="mb-10">
          <Title level={1}>{t('privacyPolicy.title')}</Title>
          <Paragraph>{t('privacyPolicy.lastUpdated', { date: 'September 17, 2024' })}</Paragraph>
          <Paragraph>{t('privacyPolicy.description')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.interpretationDefinitions')}</Title>
          <Title level={3}>{t('privacyPolicy.interpretation')}</Title>
          <Paragraph>{t('privacyPolicy.interpretationText')}</Paragraph>

          <Title level={3}>{t('privacyPolicy.definitions')}</Title>
          <ul>
            <li>
              <Paragraph><strong>{t('privacyPolicy.account')}</strong> - {t('privacyPolicy.accountDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.affiliate')}</strong> - {t('privacyPolicy.affiliateDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.company')}</strong> - {t('privacyPolicy.companyDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.cookies')}</strong> - {t('privacyPolicy.cookiesDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.country')}</strong> - {t('privacyPolicy.countryDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.device')}</strong> - {t('privacyPolicy.deviceDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.personalData')}</strong> - {t('privacyPolicy.personalDataDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.service')}</strong> - {t('privacyPolicy.serviceDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.serviceProvider')}</strong> - {t('privacyPolicy.serviceProviderDefinition')}</Paragraph>
            </li>
            <li>
              <Paragraph><strong>{t('privacyPolicy.usageData')}</strong> - {t('privacyPolicy.usageDataDefinition')}</Paragraph>
            </li>
          </ul>

          <Title level={2}>{t('privacyPolicy.collectingData')}</Title>
          <Title level={3}>{t('privacyPolicy.typesOfData')}</Title>
          <Title level={4}>{t('privacyPolicy.personalData')}</Title>
          <Paragraph>{t('privacyPolicy.personalDataDescription')}</Paragraph>
          <ul>
            <li><Paragraph>{t('privacyPolicy.emailAddress')}</Paragraph></li>
            <li><Paragraph>{t('privacyPolicy.firstNameLastName')}</Paragraph></li>
            <li><Paragraph>{t('privacyPolicy.usageData')}</Paragraph></li>
          </ul>

          <Title level={4}>{t('privacyPolicy.usageData')}</Title>
          <Paragraph>{t('privacyPolicy.usageDataDescription')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.useOfData')}</Title>
          <Paragraph>{t('privacyPolicy.useOfDataText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.retentionOfData')}</Title>
          <Paragraph>{t('privacyPolicy.retentionOfDataText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.transferOfData')}</Title>
          <Paragraph>{t('privacyPolicy.transferOfDataText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.deleteData')}</Title>
          <Paragraph>{t('privacyPolicy.deleteDataText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.disclosureOfData')}</Title>
          <Title level={3}>{t('privacyPolicy.businessTransactions')}</Title>
          <Paragraph>{t('privacyPolicy.businessTransactionsText')}</Paragraph>

          <Title level={3}>{t('privacyPolicy.lawEnforcement')}</Title>
          <Paragraph>{t('privacyPolicy.lawEnforcementText')}</Paragraph>

          <Title level={3}>{t('privacyPolicy.otherLegalRequirements')}</Title>
          <Paragraph>{t('privacyPolicy.otherLegalRequirementsText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.securityOfData')}</Title>
          <Paragraph>{t('privacyPolicy.securityOfDataText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.childrensPrivacy')}</Title>
          <Paragraph>{t('privacyPolicy.childrensPrivacyText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.linksToOtherSites')}</Title>
          <Paragraph>{t('privacyPolicy.linksToOtherSitesText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.changesToPolicy')}</Title>
          <Paragraph>{t('privacyPolicy.changesToPolicyText')}</Paragraph>

          <Title level={2}>{t('privacyPolicy.contactUs')}</Title>
          <ul>
            <li><Paragraph>{t('privacyPolicy.email')}</Paragraph></li>
            <li><Paragraph>{t('privacyPolicy.website')}</Paragraph></li>
          </ul>
        </Col>
      </div>
    </div>
  );
};

export default withTranslation()(Privacy);
