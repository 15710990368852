import React, { Component, Fragment } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import UserLogin from "../../components/Auth/UserLogin";

class UserLoginPage extends Component {
  render() {
    const setUser = this.props.setUser;
    const user = this.props.user;

    return (
      <Fragment>
        <Navbar />
        <UserLogin setUser={setUser} user={user} />
        <Footer />
      </Fragment>
    );
  }
}

export default UserLoginPage;
