import React, { useState } from "react";
import cogoToast from "cogo-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { listCart, removeItem, itemPlus, itemMinus } from "../../network/cart";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addOrder } from "../../network/order";
import {
  Button, Empty, Flex, Typography, Row, Card,
  Col, Form,
  Input,
  Select,
} from "antd";
import CartLoading from "../PlaceHolder/CartLoading";
import { DeleteFilled, MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const Cart = ({ t, i18n }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [loadingItemId, setLoadingItemId] = useState(null);

  let totalPriceSum = 0;

  const { data: cartData, isLoading } = useQuery({
    queryKey: [queryKeys.listCart, i18n],
    queryFn: listCart,
    enabled: !!i18n,
  });

  const { mutate: itemPlusHandler, isPending: isIncrementPending } = useMutation({
    mutationFn: (params) => itemPlus(params),
    onMutate: (itemId) => {
      setLoadingItemId(itemId);
    },
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t('messages.itemQuantityIncreased'), {
          position: "top-right",
        });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
      }
      setLoadingItemId(null);
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
      setLoadingItemId(null);
    },
  });

  const { mutate: itemMinusHandler, isPending: isDecrementPending } = useMutation({
    mutationFn: (params) => itemMinus(params),
    onMutate: (itemId) => {
      setLoadingItemId(itemId);
    },
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t('messages.itemQuantityDecreased'), {
          position: "top-right",
        });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
      }
      setLoadingItemId(null);
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
      setLoadingItemId(null);
    },
  });

  const { mutate: removeItemMutation, isPending: isRemovePending } = useMutation({
    mutationFn: (params) => removeItem(params),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t('messages.cartItemRemoved'), {
          position: "top-right",
        });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
    },
  });

  const { mutate: onBuyNow, isPending: isBuyPending } = useMutation({
    mutationFn: async (formData) => await addOrder(formData),
    onSuccess: async (data) => {
      if (data.status === "true") {
        if (data.data.payment_url) {
          window.location.href = data.data.payment_url;
        } else {
          cogoToast.success(t("messages.orderPlacedSuccessfully"), {
            position: "top-right",
          });
          queryClient.invalidateQueries([queryKeys.listOrders]);
          navigate("/orders");
        }
      } else {
        cogoToast.error(t("messages.errorOccurred"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error(t("messages.errorOccurred"), {
        position: "top-right",
      });
    },
  });

  const onFinish = async (values) => {
    onBuyNow(values);
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return <CartLoading isLoading={isLoading} />;
  }

  if (cartData?.data?.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <Empty description={
          <Typography.Text>
            {t('cartEmpty')} <a href="/">{t('addNow')}</a>
          </Typography.Text>
        }
        />
      </div>
    );
  }

  const MyView = cartData?.data?.map((CartList) => {
    totalPriceSum = totalPriceSum + parseInt(CartList.total_price);
    return (
      <Row gutter={20} className="mb-12" key={CartList.id}>
        <Col xl={3} lg={3} md={8} sm={24} xs={24} className="mb-3">
          <img
            src={CartList?.items?.image ?? CartList?.items?.images[0]}
            alt="product1"
            width="100px"
          />
        </Col>
        <Col xl={16} lg={6} md={8} sm={24} xs={24}>
          <div>
            <span className="text-[14px] text-black">
              {CartList.items?.name ?? CartList.items?.title}
            </span>
            <br />
            <span className="xl:text-[14px] text-[#757575]">
              {t('quantity')}: {CartList?.quantity}
            </span>
            <br />
            <span className="text-[14px] text-[#757575]">
              {t('price')}:
              <span className="mx-1">
                {CartList.is_product === 0 ? (
                  <>
                    <Typography.Text className="text-[#0F63E3]">{CartList.items.price - (CartList.items.price * (CartList.items.percentage_discount / 100))}{t('currency')}</Typography.Text>
                    <Typography.Text delete type="secondary" className="mt-10 mx-1">{CartList.items.price}{t('currency')}</Typography.Text>
                    <Typography.Text className="text-[#0F63E3]" type="secondary">{CartList.items.percentage_discount}%</Typography.Text>
                  </>
                ) : (
                  CartList.items.new_price === null ?
                    <Typography.Text className="text-[#0F63E3]">{CartList.items.price}{t('currency')}</Typography.Text> :
                    <>
                      <Typography.Text className="text-[#0F63E3]">{CartList.items.new_price}{t('currency')}</Typography.Text>
                      <Typography.Text delete type="secondary" className="mx-2">{CartList.items.price}{t('currency')}</Typography.Text>
                    </>
                )}
              </span>
            </span>
            <br />
            <span className="text-[14px] text-[#757575]">
              {t('totalPrice')} :
              <span className="text-[14px] text-[#0F63E3] mx-1">
                {CartList?.total_price}{t('currency')}
              </span>
            </span>
          </div>
        </Col>
        <Col xl={4} lg={3} md={8} sm={24} xs={24}>
          <Row>
            <Col>
              <div className="xl:text-[14px] text-black mb-4">
                {t('quantity')}
              </div>
              <Flex>
                <Button
                  type="primary"
                  onClick={() => itemPlusHandler(CartList.id)}
                  icon={<PlusOutlined />}
                  size="small"
                  loading={isIncrementPending && loadingItemId === CartList.id}
                />

                <Button
                  disabled={CartList.quantity === "1"}
                  onClick={() => itemMinusHandler(CartList.id)}
                  loading={isDecrementPending && loadingItemId === CartList.id}
                  className="mx-2"
                  type="primary"
                  icon={<MinusOutlined />}
                  size="small" />

                <DeleteFilled
                  className="text-red-500 mx-4" onClick={() =>
                    removeItemMutation(CartList.id)}
                  style={{ fontSize: "24px" }}
                  loading={isRemovePending} />
              </Flex>
            </Col>
          </Row>

        </Col>
        <hr />
      </Row>
    );
  });

  return (
    <div className="p-10 bg-gray-100">
      <div className="container">
        <Row gutter={17}>
          <Col xl={18} lg={18} md={16} sm={24} xs={24} className="mb-10" >
            <Card className="border-none" title={t('myCart')}>
              {MyView}
            </Card>
          </Col>
          <Col xl={6} lg={6} md={8} sm={24} xs={24} >
            <Card className="border-none" title={t('orderSummary')}>
              <Form form={form} onFinish={onFinish}>
                <Form.Item
                  name="delivery_address"
                  rules={[
                    {
                      required: true,
                      message: 'Please input address',
                    },
                  ]}
                >
                  <Input.TextArea placeholder={t('address')} showCount maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="payment_method"
                  rules={[
                    {
                      required: true,
                      message: 'Please select payment!',
                    },
                  ]}
                >
                  <Select placeholder={t('selectPaymentMethod')}>
                    <Option value="cash">{t('paymentMethod.cash')}</Option>
                    <Option value="credit_card">{t('paymentMethod.creditCard')}</Option>
                  </Select>
                </Form.Item>

                <span className="text-[12px] text-[#757575]">
                  {t('totalItems')}: {cartData?.data.length}
                </span>
                <br />
                <span className="text-[12px] text-[#757575]">
                  {t('totalPrice')}:
                  <span className="text-[12px] text-[#757575]" >
                    {totalPriceSum}{t('currency')}
                  </span>
                </span>
                <hr />

                <Button loading={isBuyPending} type="primary" htmlType="submit" className="w-full mt-5">
                  {t('checkOut')}
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withTranslation()(Cart);
