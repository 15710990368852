import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";

export const productSearch = catchAsync(async (key) => {
  const { data } = await axiosInstance.get(`search/${key}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

