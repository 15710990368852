import React, { Component, Fragment } from "react";
import AllProduct from "../../components/Product";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

class AllProductPage extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar  user={User}/>
        <AllProduct  user={User} />
        <Footer />
      </Fragment>
    );
  }
}

export default AllProductPage;
