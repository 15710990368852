import React, { Component, Fragment } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ForgotPassword from "../../components/Auth/ForgotPassword";

class ForgetPasswordPage extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <ForgotPassword />
        <Footer />
      </Fragment>
    );
  }
}

export default ForgetPasswordPage;
