import React, { useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Card,
  Typography
} from 'antd';
import { withTranslation } from 'react-i18next';
import { updateUser } from "../../network/auth";
import queryClient from "../../services/react-query/queryClient";
import { queryKeys } from "../../services/react-query/queryKeys";
import cogoToast from "cogo-toast";
import { useMutation } from "@tanstack/react-query";

const AccountSetting = ({ user, t }) => {
  const [form] = Form.useForm();

  const { mutate: updateUserInfo, isPending } = useMutation({
    mutationFn: (updatedData) => updateUser(user.id, updatedData),
    onSuccess: () => {
      cogoToast.success(t("messages.updateData"), { position: "top-right" });
      queryClient.invalidateQueries([queryKeys.userData]);
    },
    onError: () => {
      cogoToast.warn(t("messages.warningOccurred"), { position: "top-right" });
    }
  });

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
    });
  }, [user, form]);

  const onFinish = (values) => {
    updateUserInfo({ ...values });
  };

  return (
    <div className="p-10 bg-[#eff0f5]">
      <div className="container">
        <Typography.Title
          level={2}
        >
          {t('profile')}
        </Typography.Title>
        <Typography.Title
          level={4}
          type="secondary"
        >
          {t('manage')}
        </Typography.Title>
        <Card className="border-none" >

          <Form form={form} name="basic" onFinish={onFinish} autoComplete="true"
            initialValues={{
              name: user?.name,
              email: user?.email,
            }}
          >
            <Form.Item
              label={t('auth.name')}
              required={false}
              name="name"
              rules={[
                { required: true, message: t('auth.nameValidationRequired'), whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={t('auth.email')}
              required={false}
              rules={[
                { type: 'email', message: t('auth.emailValidation') },
                { required: true, message: t('auth.emailValidationRequired') }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isPending}>
                {t('infoUpdate')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default withTranslation()(AccountSetting);