import React from "react";
import {
    Row,
    Col,
    Typography,
    Flex,
    Spin,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { OrderDetails } from "../../network/order";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { Badge, Card, Descriptions, Steps } from 'antd';
const { Meta } = Card;

const getCurrentStep = (status) => {
    switch (status) {
        case 'pending':
            return 0;
        case 'processing':
            return 1;
        case 'onDelivery':
            return 2;
        case 'completed':
            return 3;
        default:
            return 0;
    }
};

const OrderTracking = () => {
    const { t, i18n } = useTranslation();
    const { code } = useParams();
    const { data: orderData, isLoading, error } = useQuery({
        queryKey: [queryKeys.listOrders, code, i18n],
        queryFn: () => OrderDetails(code),
        enabled: !!i18n,
    });

    if (isLoading) {
        return (
            <Flex className="my-40" align="center" gap="middle" justify="center" >
                <Spin size="large" />
            </Flex>
        );
    }

    if (error) {
        return <div>{t('Error loading order details')}</div>;
    }

    const getCurrentStatus = (status) => {
        switch (status) {
            case 'pending':
                return t('orderStatus.pending');
            case 'processing':
                return t('orderStatus.processing');
            case 'onDelivery':
                return t('orderStatus.onDelivery');
            case 'completed':
                return t('orderStatus.complete');
            default:
                return 0;
        }
    };

    const getCurrentPayment = (payment) => {
        switch (payment) {
            case 'credit_card':
                return t('paymentMethod.credit_card');
            case 'cash':
                return t('paymentMethod.cash');
            default:
                return 0;
        }
    };


    const totalPrice = orderData.data.delivery_charge ? parseInt(orderData.data.total_price) + parseInt(orderData.data.delivery_charge) : parseInt(orderData.data.total_price);

    const items = [
        {
            key: '1',
            label: <div>{t('paymentMethod.paymentMethod')}</div>,
            children: getCurrentPayment(orderData.data.payment_method),
        },
        {
            key: '2',
            label: t('orderTime'),
            children: orderData.data.date,
        },
        {
            key: '3',
            label: t('status'),
            children: <Badge status={"processing"} text={getCurrentStatus(orderData.data.status)} />,
            span: 3,
        },
        {
            key: '7',
            label: t('orderTotal'),
            children: orderData.data.total_price,
        },
        {
            key: '8',
            label: t('deliveryCharge'),
            children: orderData.data.delivery_charge,
        },
        {
            key: '9',
            label: t('totalPrice'),
            children: totalPrice,
        },
        {
            key: '10',
            label: t('deliveryAddress'),
            children: (
                <>
                    {orderData.data.delivery_address}
                </>
            ),
        },
    ];

    return (
        <div className="p-10 bg-gray-100" >
            <div className="container">
                <Steps
                    current={getCurrentStep(orderData.data.status)}
                    className="mb-12"
                    direction="horizontal"
                    items={[
                        {
                            title: t('orderStatus.pending'),
                        },
                        {
                            title: t('orderStatus.processing'),
                        },
                        {
                            title: t('orderStatus.onDelivery'),
                        },
                        {
                            title: t('orderStatus.completed'),
                        },
                    ]}
                />

                <Descriptions title={t('orderSummary')} bordered items={items} className="bg-white p-3 my-5" />

                <Typography.Title
                    level={4}
                    className="mt-10"
                >
                    {t('orderContent')}
                </Typography.Title>

                <Row className="mb-20" gutter={20} >

                    {orderData.data.items.map((item) => (
                        <Col xl={6} lg={4} md={12} sm={12} key={item.item_id}>
                            <Badge.Ribbon text={item.itemable_type === "Product" ? t('product') : t('package')} color="blue">
                                <Link to={item.itemable_type === "Product"
                                    ? `/products/${item.itemable.id}/details`
                                    : `/offers/${item.itemable.id}/details`
                                }
                                    className="custom-links"
                                    style={{ textDecoration: "none" }}>
                                    <Card
                                        size="small"
                                        hoverable
                                        className="mt-4"
                                        cover={<img alt="example" src={item.itemable.image ?? item.itemable.images[0]} style={{
                                            width: "100%",
                                            height: "300px",
                                        }} />}
                                    >
                                        <Meta title={item.name} description={
                                            <div>
                                                <p className="fs-4">{t('quantity')}: {item.quantity}</p>
                                                <p className="fs-4">{t('itemPrice')}: {item.itemable.new_price ?? item.itemable.price}{t('currency')}</p>
                                            </div>
                                        } />
                                    </Card>
                                </Link>
                            </Badge.Ribbon>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default OrderTracking;
