import React, { Component, Fragment } from "react";
import { Col, Row, Typography } from "antd";
import { t } from "i18next";
import { Link } from "react-router-dom";

class ProductLoading extends Component {
  render() {
    let isLoading = this.props.isLoading;

    return (
      <Fragment>
        <div className={isLoading}>
          <div className="container">
            <div className="text-center">
              <Typography.Title level={2}>
                {t('home.products')}
              </Typography.Title>
              <Typography.Text data-aos="fade-up" className="text-xs text-gray-400">
                {t('home.productDescription')}
                <Link className="text-decoration-none" to="/products">
                  &nbsp;{t('home.see')} {t('home.more')}
                </Link>
              </Typography.Text>
            </div>
            <Row gutter={10}>
              <Col xl={4} lg={3} md={4} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={4} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2 `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={4} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={4} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={4} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={3} md={4} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ProductLoading;
