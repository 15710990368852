import React, { Component, Fragment } from "react";

class NotFoundPage extends Component {
  render() {
    return (
      <Fragment>
        <div className="container text-center">
          <h5 className="mt-5">
            <i class="fas fa-exclamation-triangle"></i> 404 - Not Found
          </h5>
        </div>
      </Fragment>
    );
  }
}

export default NotFoundPage;
