import React, { Component, Fragment } from "react";
import Cart from "../../components/Cart";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

class CartPage extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User} />
        <Cart user={User} />
        <Footer />
      </Fragment>
    );
  }
}

export default CartPage;
