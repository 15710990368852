import React, { Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import TrakingOrder from "../../components/TrakingOrder";

const OrderTrackingPage = ({ user }) => {
  return (
    <Fragment>
      <Navbar user={user} />
      <TrakingOrder user={user} />
      <Footer />
    </Fragment>
  );
};

export default OrderTrackingPage;