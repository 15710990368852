import React from "react";
import { Row, Col } from "antd";
import { Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import OrderLoading from "../PlaceHolder/OrderLoading";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listOrders } from "../../network/order";
import { Badge, Card, Empty, Flex, Typography } from 'antd';
import { EyeOutlined } from "@ant-design/icons";

const OrderList = ({ t, i18n }) => {

  const { data: orderData, isLoading } = useQuery({
    queryKey: [queryKeys.listOrders, i18n],
    queryFn: listOrders,
    enabled: !!i18n,
  });

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return (
      <div className="p-10 bg-gray-100">
        <div className="container">
          <OrderLoading />
        </div>
      </div>
    );
  }

  if (orderData?.data.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <div className="container">
          <Empty className="text-center" description={t('noOrders')} />
        </div>
      </div>
    );
  }

  const getCurrentStatus = (status) => {
    switch (status) {
      case 'pending':
        return t('orderStatus.pending');
      case 'processing':
        return t('orderStatus.processing');
      case 'onDelivery':
        return t('orderStatus.onDelivery');
      case 'completed':
        return t('orderStatus.complete');
      default:
        return 0;
    }
  };

  const orderListView = orderData?.data.map((order, index) => (
    <Col
      xl={8}
      lg={8}
      md={8}
      sm={12}
      xs={24}
      key={index}
      className={orderData?.data.length <= 3 ? "mb-20" : "mb-4"}
    >
      <Badge.Ribbon text={getCurrentStatus(order.status)}>
        <Card title={
          <Link className="custom-links" to={`/orders/tracking/${order.id}`}>
            <EyeOutlined className="text-gray-500 text-[16px]" />
          </Link>
        } size="small" >
          <Flex justify="space-between">
            <span >{t('orderDate')}</span>
            <span >{order.date}</span>
          </Flex>
          <Flex justify="space-between">
            <span >{t('orderTotal')}</span>
            <span >{order.total_price} {t('currency')}</span>
          </Flex>
        </Card>
      </Badge.Ribbon>
    </Col>
  ));

  return (
    <div className="p-10 bg-gray-100" >
      <div className="container">
        <Typography.Title
          level={4}
          className="mb-5"
        >
          {t('orders')}
        </Typography.Title>
        <Row gutter={20} className="mt-5">{orderListView}</Row>
      </div>
    </div>
  );
};

export default withTranslation()(OrderList);
