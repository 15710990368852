import React, { Component, Fragment } from "react";

class CartLoading extends Component {
  render() {
    return (
      <Fragment>
        {/* End Breadcrumb */}
        <div className={`bg-[#eff0f5] py-5`}>
          {/* Start Skeleton Placeholder */}
          <div className="container">
            <div className="ph-item">
              <div className="ph-col-2">
                <div className="ph-picture"></div>
              </div>
              <div className="ph-col-10">
                <div className="ph-row">
                  <div className="ph-col-4 big"></div>
                  <div className="ph-col-4 empty big"></div>
                  <div className="ph-col-4 big"></div>

                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4 "></div>
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4 "></div>
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4 "></div>

                  <div className="ph-col-4"></div>
                  <div className="ph-col-6 empty"></div>

                  <div className="ph-col-4"></div>
                  <div className="ph-col-6 empty"></div>
                </div>
              </div>
              <div className="ph-col-2">
                <div className="ph-picture"></div>
              </div>
              <div className="ph-col-10">
                <div className="ph-row">
                  <div className="ph-col-4 big"></div>
                  <div className="ph-col-4 empty big"></div>
                  <div className="ph-col-4 big"></div>

                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4 "></div>
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4 "></div>
                  <div className="ph-col-4"></div>
                  <div className="ph-col-4 empty"></div>
                  <div className="ph-col-4 "></div>

                  <div className="ph-col-4"></div>
                  <div className="ph-col-6 empty"></div>

                  <div className="ph-col-4"></div>
                  <div className="ph-col-6 empty"></div>
                </div>
              </div>
            </div>
           
            {/* End Skeleton Placeholder */}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CartLoading;
