import React from "react";
import { Card, Form, Button, Input, Flex, Typography } from "antd";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';
import { useMutation } from "@tanstack/react-query";
import { authRegister } from "../../network/auth";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import 'react-phone-number-input/style.css'

const UserRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: onRegister, isPending } = useMutation({
    mutationFn: (formData) => authRegister(formData),
    onSuccess: (data) => {
      if (data?.false !== false) {
        cogoToast.success(t("User Register successfully"), { position: "top-right" });
        navigate("/login");
      } else {
        cogoToast.warn(t("Email Or Password Wrong"), { position: "top-right" });
      }

    },
    onError: () => {
      cogoToast.warn(t("Something went wrong"), { position: "top-right" });
    }
  });

  const onFinish = (values) => {
    const phoneNumber = parsePhoneNumber(values?.phone || "");
    if (phoneNumber?.number) {
      values.phone = phoneNumber.number;
    }

    onRegister(values);
  };

  if (localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="bg-[#eff0f5]">
      <div className="container p-10">
        <Typography.Title
          level={4}
          className="text-center mt-10"
        >
          {t("auth.signUpTitle")}
        </Typography.Title>
        <Typography.Title
          level={5}
          type="secondary"
          className="text-center"
        >
          {t('auth.signUpSubTitle')}{" "}
          <Link to="/login">{t('auth.login')}</Link>{" "}
          {t('auth.here')}
        </Typography.Title>
        <Flex justify="center" className="my-10">
          <Card style={{ width: "600px" }}>
            <Form form={form} name="register" onFinish={onFinish} layout="vertical">
              <Form.Item
                name="name"
                label={t('auth.name')}
                required={false}
                rules={[
                  { required: true, message: t('auth.nameValidationRequired'), whitespace: true },
                ]}
              >
                <Input className="h-[38px]" placeholder={t("auth.namePlaceholder")} />
              </Form.Item>
              <Form.Item
                name="email"
                label={t('auth.email')}
                required={false}
                rules={[
                  { type: 'email', message: t('auth.emailValidation') },
                  { required: true, message: t('auth.emailValidationRequired') }
                ]}
              >
                <Input className="h-[38px]" placeholder={t("auth.emailPlaceholder")} />
              </Form.Item>
              <Form.Item
                name={"phone"}
                label={t('auth.phone')}
                colon={false}
                required={false}
                hasFeedback
                rules={[
                  { required: true, message: t('auth.phoneValidationRequired') },
                  {
                    validator: (_, value) => {
                      if (value && !isValidPhoneNumber(value)) {
                        return Promise.reject(new Error(t('auth.phoneValidation')));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <PhoneInputWithCountrySelect
                  defaultCountry="SA"
                  international
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={t("password")}
                required={false}
                rules={[
                  { required: true, message: t('auth.passwordValidationRequired') }
                ]}
                hasFeedback
              >
                <Input.Password className="h-[38px]" placeholder={t("auth.passwordPlaceholder")} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" loading={isPending} htmlType="submit" className="w-full">
                  {t("auth.signUp")}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Flex>
      </div>
    </div>
  );
}

export default withTranslation()(UserRegister);
