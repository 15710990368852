import React, { Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ProductDetails from "../../components/ProductDetails";
import { useParams } from "react-router-dom";
import { queryKeys } from "../../services/react-query/queryKeys";
import { productDetails } from "../../network/product";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import CartLoading from "../../components/PlaceHolder/CartLoading";

const ProductDetailsPage = ({ user }) => {
  const { code } = useParams();
  const { i18n } = useTranslation();

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listProducts, code, i18n],
    queryFn: () => productDetails(code),
    enabled: !!i18n && !!code,
  });

  if (isLoading) {
    return (
      <Fragment>
        <Navbar user={user} />
        <CartLoading isLoading={isLoading} />
        <Footer />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Navbar user={user} />
      <ProductDetails user={user} data={productData?.data} />
      <Footer />
    </Fragment>
  );
};

export default ProductDetailsPage;