import React, { Component, Fragment } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Profile from "../../components/UserProfile";

class ProfilePage extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User}/>
        <Profile user={User} />
        <Footer />
      </Fragment>
    );
  }
}

export default ProfilePage;
