import React, { Component, Fragment } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ResetPassword from "../../components/Auth/ResetPassword";

class ResetPasswordPage extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <ResetPassword />
        <Footer />
      </Fragment>
    );
  }
}

export default ResetPasswordPage;
