import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import SearchList from "../../components/Search";
import { productSearch } from "../../network/productSearch";
import { queryKeys } from "../../services/react-query/queryKeys";
import CategoryLoading from "../../components/PlaceHolder/CategoryLoading";
import { useTranslation } from "react-i18next";

const SearchPage = ({ user }) => {
  const { searchkey } = useParams();
  const { i18n } = useTranslation();

  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.listProducts, searchkey, i18n],
    queryFn: () => productSearch(searchkey),
    enabled: !!searchkey && !!i18n,
  });

  if (isLoading) {
    return (
      <Fragment>
        <Navbar user={user} />
        <CategoryLoading isLoading="true"/>
        <Footer />
      </Fragment>
    );
  }

  if (error) {
    return (
      <Fragment>
        <Navbar user={user} />
        <div className="pt-5 pb-5 bg-[#eff0f5]">
          <h2>Error loading products</h2>
        </div>
        <Footer />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Navbar user={user} />
      <SearchList SearchKey={searchkey} ProductData={data?.data} user={user} />
      <Footer />
    </Fragment>
  );
};

export default SearchPage;
