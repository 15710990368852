import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

export const auth = catchAsync(async () => {
  const { data } = await axiosInstance.get("auth");
  return data;
});

export const updateUser = catchAsync(async (id, body) => {
  const { data } = await axiosInstance.put(`users/${id}`, body);
  return data;
});

export const authLogin = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("login", body);
  return data;
});

export const authRegister = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("register", body);
  return data;
});

export const authForgotPassword = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("forget-password", body);
  return data;
});

export const authResetPassword = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("reset-password", body);
  return data;
});
